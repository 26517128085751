/* -----> Color picker styles on focus*/
.color-picker-input:focus-within {
  border: 1px solid theme("colors.primary.aqua.dark");
  box-shadow: theme("boxShadow.input");
  outline: none;
}

.color-picker-button:focus {
  border: 1px solid theme("colors.primary.aqua.dark");
  box-shadow: theme("boxShadow.input");
  outline: none; 
}

.color-picker-button::-webkit-color-swatch-wrapper {
  padding: 0px;
}